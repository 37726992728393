import React from "react";
import { MDBNavbarBrand } from "mdbreact";

const NavbarLogo = ({ animation, onclickHandler }) => {
  return (
    <MDBNavbarBrand
      className={
        animation
          ? "navbar-brand animated fadeIn slower"
          : "navbar-brand animated fadeOut delay-1s"
      }
      style={{ mixBlendMode: "exclusion" }}
    >
      <a href="/#Hero" className={"navbar-image"} onClick={onclickHandler}>
        <svg
          className="etales-logo"
          xmlns="http://www.w3.org/2000/svg"
          width="175"
          height="52.368"
          viewBox="0 0 200 52.368"
        >
          <g id="Etales" transform="translate(-9.793 -3.582)">
            <path
              id="Path_12"
              data-name="Path 12"
              d="M20.267,36.213c.416,4.855,3.884,7.214,10.751,7.214a27.7,27.7,0,0,0,10.821-2.082,11.8,11.8,0,0,1-2.5,7.838A22.868,22.868,0,0,1,28.382,51.61c-12.138,0-18.589-7-18.589-18.242,0-11.584,6.936-19.075,17.826-19.075,10.127,0,14.775,6.243,14.775,15.4a21.42,21.42,0,0,1-1.041,6.521ZM32.96,28.929c.555-3.606-.763-7.144-5.757-7.144-4.3,0-6.936,2.775-7.214,7.144Z"
              transform="translate(0 4.34)"
              fill="#fff"
            />
            <path
              id="Path_13"
              data-name="Path 13"
              d="M55.386,11.175V53.418a6.749,6.749,0,0,1-3.121.483,7.093,7.093,0,0,1-3.19-.483V11.175H34.024a6.546,6.546,0,0,1-.485-2.843,6.225,6.225,0,0,1,.485-2.775H70.369a6.225,6.225,0,0,1,.485,2.775,6.547,6.547,0,0,1-.485,2.843Z"
              transform="translate(9.62 0.8)"
              fill="#fff"
            />
            <path
              id="Path_14"
              data-name="Path 14"
              d="M77.138,47.2c-2.011,2.636-5.2,4.092-9.78,4.092-5.965,0-11.167-2.843-11.167-10.4,0-8.116,6.034-11.445,13.872-11.445A19.132,19.132,0,0,1,77,30.552v-1.8c0-5.757-2.219-8.184-7.629-8.184a20.455,20.455,0,0,0-10.612,3.052,8.678,8.678,0,0,1,.693-6.035,23.115,23.115,0,0,1,11.028-2.5c8.254,0,12.486,3.538,12.486,13.041V43.244c0,2.012,1.04,2.567,2.428,2.567a6.045,6.045,0,0,0,3.259-.972,8.528,8.528,0,0,1-1.179,5.2A8.916,8.916,0,0,1,82.9,51.29c-3.121,0-4.925-1.526-5.479-4.092ZM77,35.407a17.4,17.4,0,0,0-6.034-1.04c-5.757,0-8.74,2.566-8.74,6.242,0,3.607,2.914,5.2,6.659,5.2,2.983,0,6.312-.972,8.115-3.607Z"
              transform="translate(18.798 4.66)"
              fill="#fff"
            />
            <path
              id="Path_15"
              data-name="Path 15"
              d="M88.508,55.95c-4.717,0-7.075-2.7-7.075-8.254V4.9a11.97,11.97,0,0,1,6.035-1.319v43c0,3.052,1.109,3.815,3.121,3.815a6.87,6.87,0,0,0,4.231-1.388A9.263,9.263,0,0,1,93.5,54.7,9.479,9.479,0,0,1,88.508,55.95Z"
              transform="translate(29.024 0)"
              fill="#fff"
            />
            <path
              id="Path_16"
              data-name="Path 16"
              d="M97.761,35.06c.277,7.561,4.994,10.752,12.485,10.752a20.514,20.514,0,0,0,10.474-2.636,8.552,8.552,0,0,1-1.526,5.618,20.289,20.289,0,0,1-10.335,2.5c-11.167,0-17.341-6.937-17.341-17.757,0-10.751,6.243-18.45,16.509-18.45,9.155,0,13.385,5.965,13.385,14.358a17.7,17.7,0,0,1-.832,5.618ZM115.448,30c.486-4.578-.971-9.711-7.7-9.711-5.9,0-9.433,3.884-9.919,9.711Z"
              transform="translate(33.11 4.66)"
              fill="#fff"
            />
            <path
              id="Path_17"
              data-name="Path 17"
              d="M125.8,46.02c4.509,0,7.075-1.8,7.075-4.786,0-7.838-16.786-4.925-16.786-16.37,0-6.381,3.954-9.78,11.167-9.78a16.174,16.174,0,0,1,9.018,2.428,10.491,10.491,0,0,1,1.109,6.034,15.312,15.312,0,0,0-9.5-3.26c-4.023,0-5.688,1.526-5.688,3.954,0,7.144,16.786,4.439,16.786,16.231,0,7.838-5.757,10.82-13.04,10.82-3.538,0-7.422-.693-9.989-2.219a10.511,10.511,0,0,1-1.109-6.312A20.172,20.172,0,0,0,125.8,46.02Z"
              transform="translate(42.522 4.66)"
              fill="#fff"
            />
            <g
              id="Group_25"
              data-name="Group 25"
              transform="translate(179.969 20.721)"
            >
              <path
                id="Path_18"
                data-name="Path 18"
                d="M150.557,42.111l-15.192-8.474-3.948-13.909.581-.165,13.328-3.784,15.192,8.474Z"
                transform="translate(-130.694 -15.78)"
                fill="none"
              />
              <path
                id="Path_19"
                data-name="Path 19"
                d="M142.041,24.516"
                transform="translate(-126.39 -12.24)"
                fill="none"
              />
              <line
                id="Line_3"
                data-name="Line 3"
                x1="13.884"
                y1="7.744"
                transform="translate(1.768 4.531)"
                fill="#fff"
              />
              <path
                id="Path_20"
                data-name="Path 20"
                d="M152.127,21.81"
                transform="translate(-122.304 -13.336)"
                fill="none"
              />
              <rect
                id="Rectangle_17"
                data-name="Rectangle 17"
                width="2.599"
                height="2.599"
                rx="0.925"
                transform="translate(1.266 2.763) rotate(29.152)"
                fill="none"
              />
            </g>
            <circle
              id="Ellipse_7"
              data-name="Ellipse 7"
              cx="2.81"
              cy="2.81"
              r="2.81"
              transform="translate(47.367 32.785)"
              fill="#fff"
            />
          </g>
        </svg>
      </a>
    </MDBNavbarBrand>
  );
};

export default NavbarLogo;
