import React from "react";

const InfoBar = (props) => {
  return (
    <div className={"info-bar animated fadeIn slow"}>
      <span
        className={"info-text"}
        style={{
          color: `${props.color}`,
          marginBottom: `${props.marginBottom}`,
          marginLeft: `${props.marginLeft}`,
        }}
      >
        {props.text}
      </span>
    </div>
  );
};

export default InfoBar;
