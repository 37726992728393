import React from "react";

const data = [
  {
    id: 1,
    text: "About",
    url: "/#About",
    // dataMenuAnchor:""
  },
  {
    id: 2,
    text: "Work",
    url: "/#Work",
  },
  {
    id: 3,
    text: "Services",
    url: "/#Services",
  },
  {
    id: 4,
    text: "Contact",
    url: "/#Contact",
  },
];

// I KNOW WE CAN COMBINE IT !!!!!
//Todo Styleclass??
export default (props) => {
  const tempLinks = data.map((link) => {
    return (
      <div key={link.id} className={"navItem"}>
        <li style={{listStyle: "none"}} className={"navLink"}>
          <a
            href={link.url}
            style={{color: "white"}}
            onClick={() => {props.handleToggle(); props.handleClick()}}
          >
            {link.text}
          </a>
        </li>
      </div>
    );
  });

  return <div id="myMenu">{tempLinks}</div>;
};
