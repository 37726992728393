import React from "react";
import { MDBNavbar, MDBCardText, MDBRow, MDBCol, MDBContainer } from "mdbreact";
import InfoBar from "../infobar/InfoBar";
import NavItem from "../../constants/NavItem";
import NavbarLogo from "./EtalesLogo";
import { graphql, useStaticQuery } from "gatsby";
import Image from "gatsby-image";

const getImage = graphql`
  {
    file(relativePath: { eq: "martini.png" }) {
      childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
const Navbar = (props) => {
  const data = useStaticQuery(getImage);

  const toggleBtn = () => {
    document
      .querySelector(".hamburger--collapse")
      .classList.toggle("is-active");
  };

  return (
    <MDBNavbar className="navbar-dark navbar blackStyle">
      <NavbarLogo animation={props.dark} onclickHandler={props.handleClick} />

      <div className="reach-out">
        <MDBCardText
          tag="h5"
          className={
            props.dark ? "animated fadeIn slow" : "animated fadeOut delay-1s"
          }
        >
          <span
            className="navbar-reach-out"
            style={{
              borderBottom: "2px solid",
              display: `${props.reachOut}`,
            }}
          >
            <a href="/#Contact">REACH OUT</a>
          </span>
        </MDBCardText>
      </div>
      <button
        className="hamburger hamburger--collapse"
        type="button"
        name="nav-toggle"
        onClick={() => {
          toggleBtn();
          props.handleClick();
        }}
      >
        <div>
          <div className="hamburger-box">
            <div className="hamburger-inner"></div>
          </div>
        </div>
      </button>

      <div
        className={
          props.navbarToggled ? "navbar-toggle-open" : "navbar-toggle-close"
        }
      >
        {props.navbarToggled && (
          <div
            className={"navbar-toggle-background navbar-floating-bg"}
            onClick={() => {
              toggleBtn();
              props.handleClick();
            }}
          ></div>
        )}
        <div className={"sidebar-toggle"}>
          <div className={"sidebar-content-container"}>
            <div className={"fade-items"}>
              {props.navbarToggled && (
                <InfoBar
                  text="MENU"
                  color="white"
                  marginLeft="0.5rem"
                  marginBottom="4rem"
                />
              )}
              <NavItem
                handleClick={props.handleClick}
                handleToggle={toggleBtn}
              />
            </div>
            <div
              className={"sidebar-text-container sidebar-text-container-fade"}
            >
              <MDBContainer fluid style={{ paddingLeft: 0 }}>
                <MDBRow xs="12">
                  <MDBCol xs="9" className={"sidebar-info-styling"}>
                    <span className={"sidebar-text-styling"}>
                      Nieuwe kerkhof 9
                    </span>
                    <br />

                    <span>9712PV Groningen</span>
                  </MDBCol>
                  <MDBCol xs="3" className={"sidebar-image-styling"}>
                    <Image
                      fixed={data.file.childImageSharp.fixed}
                      alt="Martinitoren"
                      className="menu-kerk-icon"
                    />
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
              <div className={"sidebar-contact"}>
                <span className={"sidebar-text-styling"}>050 211 27 27</span>
                <br />
                <span>info@e-tales.nl</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MDBNavbar>
  );
};

export default Navbar;
